<template>
    <div class="login">
        <div class="container">
            <div class="logo">
                <img src="../../assets/images/smartStone.png" alt="" />
            </div>
            <header>
                <h1><strong>CHAUFFEURS</strong> MODULE</h1>
            </header>

            <p v-if="error" class="uk-text-danger">{{ error }}</p>

            <button type="button" @click="initiateLogin">
                Login starten
            </button>
        </div>
        <footer>
            <span>Door in te loggen gaat u akkoord met onze <strong>algemene voorwaarden.</strong></span>
            <div class="logo">
                <img src="../../assets/images/logo blauw.png" alt="" />
            </div>
        </footer>
    </div>
</template>

<style lang="scss">

.login {
    height: 100vh;

    .container {
        background:      #fff;
        justify-content: center;
        margin:          0 auto;
        padding:         50px;
        position:        relative;
        top:             200px;
        width:           50%;

        .logo {
            margin:          0 auto;
            justify-content: center;
            display:         flex;

            img {
                width:    150px;
                position: absolute;
                top:      -70px;
            }
        }

        header {
            margin-top: 50px;

            h1 {
                font-size:  20px;
                text-align: center;
                color:      #D9000D;
            }
        }

        button {
            border:          none;
            padding:         20px 70px;
            font-size:       20px;
            border-radius:   5px;
            position:        relative;
            margin:          50px auto 0;
            justify-content: center;
            display:         flex;
            background:      #1458B3;
            color:           #fff;
        }
    }

    footer {
        position:        relative;
        justify-content: center;
        margin:          0 auto;
        text-align:      center;
        top:             210px;

        span {
            font-size: 12px;
            color:     gray;
        }

        .logo {
            width:  150px;
            margin: 30px auto 0;
        }
    }

}

@media (min-width: 960px) {
    .login {
        .container {
            width: 400px;
        }
    }
}

</style>

<script>

import CONFIG from '../../../config';
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '@/api';
import axios from 'axios';

const STATE_STORAGE_KEY = 'oauth2_state';

export default {
    name:       'Login',
    components: {},

    data() {
        return {
            error: null,
        };
    },

    async created() {
        const
            location    = new URL(window.location),
            token       = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
            storedState = window.localStorage.getItem(STATE_STORAGE_KEY)
        ;

        if (token) {
            // we already have a token
            this.$router.push({ name: 'overview' });

        } else if (storedState) {
            const
                authorizationCode = location.searchParams.get('code'),
                receivedState     = location.searchParams.get('state')
            ;

            if (! receivedState || ! authorizationCode) {
                console.error('Code or state missing from authorization response'); //eslint-disable-line no-console
                return;
            }

            if (receivedState !== storedState) {
                console.error('Invalid state in authorization response'); //eslint-disable-line no-console
                return;
            }

            window.localStorage.removeItem(STATE_STORAGE_KEY);
            history.replaceState(null, '', '/');

            try {
                const response = await axios.post(
                    CONFIG.oauth2.tokenEndpoint,
                    {
                        grant_type:   'authorization_code',
                        code:         authorizationCode,
                        client_id:    CONFIG.oauth2.clientId,
                        redirect_uri: CONFIG.oauth2.redirectUri,
                    }
                );

                if (response.data.token_type !== 'bearer') {
                    //eslint-disable-next-line no-console
                    console.error(`Expected bearer token but got token_type "${response.data.token_type}"`);
                }

                window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, response.data.access_token);
                window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, response.data.refresh_token);

                this.$router.push({ name: 'overview' });

            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                this.error = e.toString();
            }
        }
    },

    methods: {
        initiateLogin() {
            const state = crypto.randomUUID();

            window.localStorage.setItem(STATE_STORAGE_KEY, state);

            const params = new URLSearchParams({
                response_type: 'code',
                client_id:     CONFIG.oauth2.clientId,
                redirect_uri:  CONFIG.oauth2.redirectUri,
                scope:         'all',
                state,
            });

            const redirectUri    = new URL(CONFIG.oauth2.authEndpoint);
            redirectUri.search   = params.toString();
            window.location.href = redirectUri.toString();
        },
    },
}
</script>
